import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const List = styled.ul`
  margin-left: 0px;
`
const Item = styled.li`
  margin: 5px;
  display: inline-block;
`

export const Links = ({ links }) => {
  return (
    <>
      <List>
        {links.map(link => (
          <Item key={link.href}>
            <a href={link.href}>{link.title}</a>
          </Item>
        ))}
      </List>
    </>
  )
}

Links.propTypes = {
  links: PropTypes.array.isRequired,
}
