import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"

import { SchoolsMap } from "../components/map"

/**
 * What can we show?
 * List of departements in region
 * List of nearby regions?
 * Info about region from Wikipedia
 * News about region from some source?
 * @param {*} props
 */
const region = props => {
  const { pageContext } = props
  const { schools, value, extra } = pageContext

  return (
    <Layout>
      <SEO lang="fr" title="Etudiant A" />
      <h1>Etudier dans la Région {value}</h1>

      {/* Extra blocks */}
      {extra?.blocks &&
        extra.blocks.map((block, idx) => {
          return <ReactMarkdown key={idx}>{block}</ReactMarkdown>
        })}

      <SchoolsMap schools={schools} />

      <h2>Liste des établissements dans la région {value}</h2>
      <ul>
        {Object.keys(schools).map(slug => {
          return (
            <li key={slug}>
              <Link to={`/${slug}`}>{schools[slug].nom}</Link>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}
export default region
