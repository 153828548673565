import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Commune from "./commune"
import Departement from "./departement"
import Region from "./region"
import TypeDetablissement from "./type_detablissement"
import Tutelle from "./tutelle"
import Academie from "./academie"
import { SchoolsMap } from "../components/map"

const groupTemplate = props => {
  const { pageContext } = props
  const { schools } = pageContext

  if (pageContext.category === "commune") {
    return <Commune {...props} />
  }

  if (pageContext.category === "departement") {
    return <Departement {...props} />
  }

  if (pageContext.category === "region") {
    return <Region {...props} />
  }

  if (pageContext.category === "type_detablissement") {
    return <TypeDetablissement {...props} />
  }

  if (pageContext.category === "tutelle") {
    return <Tutelle {...props} />
  }

  if (pageContext.category === "academie") {
    return <Academie {...props} />
  }

  console.log(pageContext.category)

  return (
    <Layout>
      <SEO title="Etudiant A" />
      <h1>Etudier</h1>

      <SchoolsMap schools={schools} />

      <h2>Liste des établissements d&apos;enseignement supérieur</h2>
      <ul>
        {Object.keys(schools).map(slug => {
          return (
            <li key={slug}>
              <Link to={`/${slug}`}>{schools[slug].nom}</Link>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}
export default groupTemplate
