import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"

import { SchoolsMap } from "../components/map"
import { Wikipedia } from "../components/wikipedia"
import { Links } from "../components/links"
import { filterLinks } from "../utils/filterLinks"

const commune = props => {
  const { pageContext } = props
  const { schools, value, extra } = pageContext

  let links = []
  if (extra?.google?.organic_results) {
    const filteredLinks = filterLinks(
      extra?.google.organic_results.map(result => ({
        title: result.title,
        href: result.link,
      }))
    )

    links.push(...filteredLinks.slice(0, 1))
    links.push({
      title: `Job étudiant à ${value}`,
      href: `https://jobetudiant.net/${value.toLowerCase()}/emplois`,
    })
    links.push(...filteredLinks.slice(1, 100))
  }

  return (
    <Layout>
      <SEO
        lang="fr"
        title="Etudiant A"
        description="Tout savoir sur la vie étudiante a {value}!"
      />
      <h1>Etudier a {value}</h1>

      {/* Wikipedia, weather, photos from flickr, jobs, nearby communes, reddit feed?  */}

      {extra?.wikipedia?.extract && (
        <Wikipedia bold={[value]} {...extra.wikipedia}></Wikipedia>
      )}

      {/* Extra blocks */}
      {extra?.blocks &&
        extra.blocks.map((block, idx) => {
          return <ReactMarkdown key={idx}>{block}</ReactMarkdown>
        })}

      {links.length > 0 && (
        <>
          <h3>Liens vie étudiante à {value}</h3>
          <Links links={links} />
        </>
      )}

      <SchoolsMap schools={schools} />
      <h2>Liste des établissements a {value}</h2>
      <ul>
        {Object.keys(schools).map(slug => {
          return (
            <li key={slug}>
              <Link to={`/${slug}`}>{schools[slug].nom}</Link>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}
export default commune
