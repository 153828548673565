const bannedHosts = [
  "letudiant.fr",
  "location-etudiant.fr",
  "immojeune.com",
  "studylease.com",
  "residenceetudiante.fr",
  "locservice.fr",
  "adele.org",
  "indeed.fr",
  "studentjob.fr",
  "studyrama.com",
  "immodvisor.com",
  "diplomeo.com",
  "expat.com",
  "lokaviz.fr",
  "fac-habitat.com",
  "nexity-studea.com",
  "lesbellesannees.com",
  "studapart.com",
  "cardinalcampus.fr",
  "wikipedia.org",
  "trovit.fr",
  "cap-logement-etudiant.com",
  "360m2.fr",
  "estudines.com",
  "suitetudes.com",
  "paruvendu.fr",
  "jooble.org",
  "fnaim.fr",
  "avendrealouer.fr",
  "bienici.com",
  "sunfimmo.com",
  "seloger.com",
]

export const filterLinks = (links = []) => {
  const seen = {}
  return links.filter(link => {
    const url = new URL(link.href)
    const rootDomain = url.host
      .split(".")
      .reverse()
      .splice(0, 2)
      .reverse()
      .join(".")
    if (seen[rootDomain]) {
      return false
    }
    seen[rootDomain] = true
    return bannedHosts.indexOf(rootDomain) == -1
  })
}
