import React, { useState } from "react"
import PropTypes from "prop-types"
import Map from "pigeon-maps"
import Marker from "pigeon-marker"
import { navigate } from "gatsby"

import mapTilerProvider from "../utils/mapTileProvider"

export const SchoolsMap = ({ schools }) => {
  let smallestLat, largestLat, smallestLon, largestLon
  let [zoom, setZoom] = useState(18)

  Object.values(schools).forEach(school => {
    if (!smallestLat || smallestLat > school.latitude_y) {
      smallestLat = school.latitude_y
    }

    if (!largestLat || largestLat < school.latitude_y) {
      largestLat = school.latitude_y
    }

    if (!smallestLon || smallestLon > school.longitude_x) {
      smallestLon = school.longitude_x
    }

    if (!largestLon || largestLon < school.longitude_x) {
      largestLon = school.longitude_x
    }
  })

  const center = [
    (largestLat - smallestLat) / 2.0 + smallestLat,
    (largestLon - smallestLon) / 2.0 + smallestLon,
  ]

  /**
   * Dynamicalluy setting zoom. I wish we could do that statically though.
   */
  const onBoundsChanged = ({ bounds }) => {
    if (bounds.ne[0] - bounds.sw[0] < largestLat - smallestLat) {
      setZoom(zoom - 1)
    } else if (bounds.ne[1] - bounds.sw[1] < largestLon - smallestLon) {
      setZoom(zoom - 1)
    }
  }
  return (
    <>
      <h2>Cartes des établissements</h2>
      <Map
        zoom={zoom}
        center={center}
        provider={mapTilerProvider}
        height={500}
        onBoundsChanged={onBoundsChanged}
      >
        {Object.keys(schools).map(slug => (
          <Marker
            key={slug}
            anchor={[schools[slug].latitude_y, schools[slug].longitude_x]}
            payload={schools[slug]}
            onClick={({ event, anchor, payload }) => {
              navigate(`/${slug}`)
            }}
          >
            {schools[slug].nom}
          </Marker>
        ))}
      </Map>
    </>
  )
}

SchoolsMap.propTypes = {
  schools: PropTypes.object,
}
